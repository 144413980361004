import { modalOpen, modalSetDate } from './modal';

let selectEventId = false;

function toCalendarEvents(event) {
  const startDate = event.start?.date;
  const endDate = event.end?.date;

  const events = {
    id: event.id,
    title: event.summary,
    start: startDate ? event.start.date : event.start.dateTime,
    end: endDate ? event.end.date : event.end.dateTime,
    description: event.description,
    source: event,
    creator: event.creator
  };
  return events;
}

const handleEventClick = (arg) => {
  const item = arg.event._def.extendedProps.source;
  const startDate = item.start?.date;
  const endDate = item.end?.date;
  const setData = {
    id: item.id,
    title: Object.keys(item).includes('summary') !== undefined ? item.summary : '',
    start: startDate ? item.start.date : item.start.dateTime,
    startStr: arg.event.startStr,
    end: endDate ? item.end.date : item.end.dateTime,
    endStr: arg.event.endStr,
    description: Object.keys(item).includes('description') !== undefined ? item.description : '',
    attendees: item.attendees,
    creator: item.creator
  }

  if (arg.event._def.extendedProps.privateAccount) {
    alert('この予定は編集出来ません\nこの予定はプライベートアカウントによって作成されています');
    return
  };

  selectEventId = arg.event.id;
  modalSetDate(setData, true);
  modalOpen();
};

const handleSelect = (arg) => {
  selectEventId = '';
  modalSetDate(arg, false);
  modalOpen();
};

const handleDrop = async (arg) => {
  const event = arg.event;
  const request = gapi.client.calendar.events.update({
    calendarId: loginAccountEmail,
    eventId: event.id,
    resource: {
      summary: event.title,
      start: event.allDay ? { date: event.startStr } : { dateTime: event.startStr },
      end: event.allDay ? { date: event.endStr } : { dateTime: event.endStr },
      description: event._def.extendedProps.source.description,
      source: event._def.extendedProps.source,
      creator: event._def.extendedProps.creator
    }
  });
  await request.execute((event) => {
    console.log(`${event.error ? 'error' : 'dropUpdate: success'} `);
  })
};

const handleResize = async (arg) => {
  const event = arg.event;
  const request = gapi.client.calendar.events.update({
    calendarId: loginAccountEmail,
    eventId: event.id,
    resource: {
      summary: event.title,
      start: event.allDay ? { date: event.startStr } : { dateTime: event.startStr },
      end: event.allDay ? { date: event.endStr } : { dateTime: event.endStr },
      description: event._def.extendedProps.source.description,
      source: event._def.extendedProps.source,
      creator: event._def.extendedProps.creator
    }
  });
  await request.execute((event) => {
    console.log(`${event.error ? 'error' : 'resizeUpdate: success'} `);
  })
}

export { toCalendarEvents, handleEventClick, handleSelect, handleDrop, handleResize, selectEventId }