import flatpickr from "flatpickr";
import { Japanese } from "flatpickr/dist/l10n/ja"
import "flatpickr/dist/flatpickr.min.css";

const modal = document.querySelector('.js-modal');
const close = document.querySelectorAll('.js-modal-close');
const eventDelete = document.getElementById('modal-eventDelete');
const title = document.getElementById('modal-title');
const startDate = document.getElementById('modal-startDate');
const endDate = document.getElementById('modal-endDate');
const description = document.getElementById('modal-description');
const attendeesList = document.getElementById('attendeesList');
let attendeesListInput;

export function setMembers(members) {
    members.forEach((member, idx) => {
        let memberLi = document.createElement('li');
        memberLi.innerHTML = `<input type="checkbox" id="attendees0${idx + 1}" value="${member.electrodreamCalendarId}"><label for="attendees0${idx + 1}">${member.name}</label>`
        attendeesList.appendChild(memberLi);
    })
    attendeesListInput = document.querySelectorAll('#attendeesList input');
}

export function modalSetDate(arg, eventClick) {
    title.value = arg.title ? arg.title : '';
    startDate.value = arg.startStr;
    endDate.value = arg.endStr;

    // 説明文を初期化
    description.innerHTML = '';

    if (eventClick) {
        if (arg.description) {
            description.innerHTML = `<textarea name="description" rows="5" placeholder="説明文">${arg.description}</textarea>`;
        }
    } else {
        description.innerHTML = '<textarea name="description" rows="5" placeholder="説明文"></textarea>';
    }

    if (arg.attendees) {
        attendeesListInput.forEach((input) => {
            arg.attendees.forEach((attendees) => {
                if (attendees.email === input.value) {
                    input.checked = true;
                }
            })
        })
    } else {
        attendeesListInput.forEach((input) => {
            input.checked = false;
        })
    }

    flatpickr(startDate, {
        locale: Japanese,
        enableTime: true
    })

    const minDate = new Date(startDate.value);
    flatpickr(endDate, {
        locale: Japanese,
        enableTime: true,
        minDate: minDate.setMinutes(minDate.getMinutes() + 5),
    })

    if (eventClick) {
        eventDelete.classList.add('is-show');
    } else {
        eventDelete.classList.remove('is-show');
    }
}

export function modalOpen() {
    modal.classList.add('is-open');
    title.focus();
}

export function modalClose(target) {
    target.disabled = false;
    modal.classList.remove('is-open');
}
close.forEach((el) => {
    el.addEventListener('click', modalClose);
})

export function setModalParams() {
    const start = new Date(startDate.value);
    const end = new Date(endDate.value);
    let startArray = { dateTime: start };
    let endArray = { dateTime: end };
    let attendeesList = [];

    if (`${start.getHours()}:${start.getMinutes()}` === `${end.getHours()}:${end.getMinutes()}`) {
        startArray = { date: `${start.getFullYear()}-${('0' + (start.getMonth() + 1)).slice(-2)}-${('0' + start.getDate()).slice(-2)}` };
        endArray = { date: `${end.getFullYear()}-${('0' + (end.getMonth() + 1)).slice(-2)}-${('0' + end.getDate()).slice(-2)}` };
    }

    attendeesListInput.forEach((input) => {
        if (input.checked) {
            attendeesList.push({ email: input.value });
        }
    })

    const event = {
        'google': {
            'summary': title.value,
            'description': description.querySelector('textarea').value,
            'start': startArray,
            'end': endArray,
            'attendees': attendeesList
        },
        'fullCalendar': {
            'title': title.value,
            'description': description.querySelector('textarea').value,
            'start': start.toISOString(),
            'end': end.toISOString()
        }
    }

    return event;
}

function modalOut(e) {
    if (e.target == modal) {
        modal.classList.remove('is-open');
    }
}
addEventListener('click', modalOut);

function changeStartDate() {
    const minDate = new Date(startDate.value);
    flatpickr(endDate, {
        locale: Japanese,
        enableTime: true,
        minDate: minDate.setMinutes(minDate.getMinutes() + 5),
        defaultDate: minDate.setMinutes(minDate.getMinutes() + 25)
    })
}
startDate.addEventListener('input', changeStartDate);
