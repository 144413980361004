"use strict";

import { Calendar } from '@fullcalendar/core';
import jaLocale from '@fullcalendar/core/locales/ja';
import dayGridPlugin from '@fullcalendar/daygrid';
import googleCalendarPlugin from '@fullcalendar/google-calendar';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import timeGridPlugin from '@fullcalendar/timegrid';
import jwt_decode from "jwt-decode";
import { modalClose, setModalParams, setMembers } from './modal';
import { toCalendarEvents, handleEventClick, handleSelect, handleDrop, handleResize, selectEventId } from './handlers';
import '../css/index.css';

const CLIENT_ID = '52274483615-d4vptnt2nm8gqab5j6brq7895c83ot9u.apps.googleusercontent.com';
const API_KEY = 'AIzaSyAL3JeBqnhh0pTmaGMAUbh2GmkXG0tUzA0';
const DISCOVERY_DOC = 'https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest';
const SCOPES = 'https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/admin.directory.group.member.readonly';
const authorizeDiv = document.getElementById('authorize');
const authorizeButton = document.getElementById('authorize_button');
const saveButton = document.getElementById('js-eventSave');
const deleteButton = document.getElementById('modal-eventDelete');
const whiteboardTokenCookie = document.cookie.match(new RegExp('whiteboardToken\=([^\;]*)\;*'));
const whiteboardPrivateAccountCookie = document.cookie.match(new RegExp('whiteboardPrivateAccount\=([^\;]*)\;*'));
const breakPoint = 690;
let tokenClient,
    width,
    gapiInited = false,
    gisInited = false,
    initDates = true,
    reFetchMonth = [],
    viewDate = new Date(),
    loginAccountEmail,
    accountsData = [],
    hiddenMembersList = [],
    defaultSources,
    calendar;

function getAccountsData() {
    // whiteboardカレンダーグループメンバーのカレンダーID（エレドリDBを想定）
    accountsData = [
        {
            'privateCalendarId': '',
            'electrodreamCalendarId': 'iino@electrodream.jp',
            'backgroundColor': '#039be5',
            'name': '飯野'
        },
        {
            'privateCalendarId': '',
            'electrodreamCalendarId': 'matsuura@electrodream.jp',
            'backgroundColor': '#3f51b5',
            'name': '松浦'
        },
        {
            'privateCalendarId': '',
            'electrodreamCalendarId': 'takanashi@electrodream.jp',
            'backgroundColor': '#7986cb',
            'name': '高梨'
        },
        {
            'privateCalendarId': '',
            'electrodreamCalendarId': 'wataru@electrodream.jp',
            'backgroundColor': '#8e24aa',
            'name': '滝澤'
        }
    ]
}

if (typeof window !== 'undefined') {
    width = window.innerWidth;
    window.addEventListener('resize', () => {
        width = window.innerWidth;
    });
}

/**
 * Callback after api.js is loaded.
 */
function gapiLoaded() {
    gapi.load('client', initializeGapiClient);
}

/**
 * Callback after the API client is loaded. Loads the
 * discovery doc to initialize the API.
 */
async function initializeGapiClient() {
    await gapi.client.init({
        apiKey: API_KEY,
        discoveryDocs: [DISCOVERY_DOC],
    });

    gapi.client.setApiKey(API_KEY);
    await gapi.client.load("https://admin.googleapis.com/$discovery/rest?version=directory_v1")
        .then(() => { console.log("GAPI client loaded for API"); },
            (err) => { location.reload(); });

    gapiInited = true;
}

/**
 * Callback after Google Identity Services are loaded.
 */
function gisLoaded() {
    tokenClient = google.accounts.oauth2.initTokenClient({
        client_id: CLIENT_ID,
        scope: SCOPES,
        prompt: ''
    });
    gisInited = true;
}

/**
 * Enables user interaction after all libraries are loaded.
 */
function maybeEnableButtons() {
    if (gapiInited && gisInited) {
        if (whiteboardTokenCookie === null) {
            // 入力補助
            if (whiteboardPrivateAccountCookie !== null) {
                document.getElementById('privateEmail').value = whiteboardPrivateAccountCookie[1];
            }
            authorizeDiv.style.visibility = 'visible';
        } else {
            authorize(false);
        }
    }
}

function authorize(btnClick) {
    if (gapiInited && gisInited) {
        tokenClient.callback = async (resp) => {
            if (resp.error !== undefined) {
                throw (resp);
            }
            await listUpcomingEvents();
        };

        // cookieにaccess_tokenがあればgapiclientにset、なければアカウント選択して同意
        if (gapi.client.getToken() === null && whiteboardTokenCookie === null) {
            // Prompt the user to select a Google Account and ask for consent to share their data
            // when establishing a new session.
            tokenClient.requestAccessToken({ hint: loginAccountEmail });
        } else {
            gapi.client.setToken({ 'access_token': whiteboardTokenCookie[1] });

            // cookieにプライベートアカウントが登録されていれば
            if (whiteboardPrivateAccountCookie !== null) {
                // TODO: 仮でプライベートアカウントを設定
                accountsData.forEach(account => {
                    if (account.electrodreamCalendarId === loginAccountEmail) {
                        account.privateCalendarId = whiteboardPrivateAccountCookie[1];
                    };
                })

                // カレンダー生成
                listUpcomingEvents();
            } else {
                // authorizeButton押下であればカレンダー生成
                btnClick ? listUpcomingEvents() : authorizeDiv.style.visibility = 'visible';
            }
        }
    }
}

// 表示メンバーリストを生成
function setMembersList() {
    accountsData.forEach((member, idx) => {
        const memberLi = document.createElement('li');
        memberLi.innerHTML = `<input type="checkbox" id="memberList${idx + 1}" class="memberList" value="${member.electrodreamCalendarId}" checked><label for="memberList${idx + 1}">${member.name}</label>`
        document.getElementById('membersList').appendChild(memberLi);
    });

    const memberList = document.querySelectorAll('.memberList');
    memberList.forEach(member => {
        member.addEventListener('click', () => {
            // 表示中のメンバーを取得
            let existSourceId = []
            calendar.getEventSources().map(source => {
                existSourceId.push(source.internalEventSource.extendedProps.electrodreamCalendarId);
            })

            memberList.forEach(el => {
                if (!el.checked) {
                    // 表示メンバーリストのチェックがないメンバーを非表示メンバーリストに追加
                    if (!hiddenMembersList.includes(el.value)) {
                        hiddenMembersList.push(el.value);
                    }
                } else {
                    // 表示メンバーリストのチェックがあり、表示されていないメンバーの予定をカレンダーに追加
                    defaultSources.map(eventSource => {
                        if (Object.keys(eventSource.internalEventSource.extendedProps).length !== 0) {
                            if (eventSource.internalEventSource.extendedProps.electrodreamCalendarId === el.value) {
                                if (!existSourceId.includes(el.value)) {
                                    calendar.addEventSource(eventSource.internalEventSource._raw);
                                }
                            }
                        }
                    })

                    // 非表示メンバーリストからチェックありのメンバーを削除
                    if (hiddenMembersList.includes(el.value)) {
                        hiddenMembersList.splice(hiddenMembersList.indexOf(el.value), 1);
                    }
                }
            });

            if (hiddenMembersList.length > 0) {
                // カレンダーから非表示メンバーリストにいるメンバーの予定を削除
                calendar.getEventSources().map((eventSource, idx) => {
                    if (Object.keys(eventSource.internalEventSource.extendedProps).length !== 0) {
                        if (hiddenMembersList.includes(eventSource.internalEventSource.extendedProps.electrodreamCalendarId)) {
                            calendar.getEventSources()[idx].remove();
                        }
                    }
                })
            }
        });
    })
}

// カレンダーイベントを取得
async function getCalenderEvents(ids) {
    let events = [];

    await Promise.all(ids.map(async id => {
        if (!id) return

        let response;
        const year = viewDate.getFullYear();
        const prevMonth = viewDate.getMonth() - 3;
        const nextMonth = viewDate.getMonth() + 4;
        const timeMin = new Date(year, prevMonth, 1, 9);
        const timeMax = new Date(year, nextMonth, 1, 9);

        try {
            const request = {
                calendarId: id,
                timeMin: timeMin.toISOString(),
                timeMax: timeMax.toISOString(),
                showDeleted: false,
                singleEvents: true,
                // maxResults: 10,
                orderBy: 'startTime',
            };
            response = await gapi.client.calendar.events.list(request);
        } catch (err) {
            document.cookie = 'whiteboardToken=; max-age=0;';
            gapiLoaded();
            gisLoaded();
            return;
        }

        if (response.result.items && response.result.items.length) {
            await response.result.items.map((item) => {
                const startDate = item.start?.date;
                const endDate = item.end?.date;

                let privateAccount = false;
                accountsData.filter(account => {
                    if (account.privateCalendarId === id) {
                        privateAccount = true;
                    }
                })

                events.push({
                    id: item.id,
                    title: privateAccount ? '予定あり' : item.summary ? item.summary : '',
                    start: startDate ? item.start.date : item.start.dateTime,
                    end: endDate ? item.end.date : item.end.dateTime,
                    description: privateAccount ? '非公開' : item.description ? item.description : '',
                    creator: item.creator,
                    source: item,
                    privateAccount: privateAccount,
                    editable: !privateAccount
                });
            });
        }
    }))
    return events;
}

/**
 * Print the summary and start datetime/date of the next ten events in
 * the authorized user's calendar. If no events are found an
 * appropriate message is printed.
 */
async function listUpcomingEvents() {
    authorizeDiv.style.display = 'none';

    // cookieにaccess_tokenを登録
    document.cookie = 'whiteboardToken=' + gapi.client.getToken().access_token + '; max-age=3600';

    // cookieにprivateEmailを登録
    const privateEmailCookie = whiteboardPrivateAccountCookie === null ? document.getElementById('privateEmail').value : whiteboardPrivateAccountCookie[1];
    document.cookie = 'whiteboardPrivateAccount=' + privateEmailCookie + '; max-age=3600';

    const calendarEl = document.getElementById('calendar');
    calendar = new Calendar(calendarEl, {
        plugins: [
            dayGridPlugin,
            timeGridPlugin,
            listPlugin,
            googleCalendarPlugin,
            interactionPlugin,
        ],
        customButtons: {
            signoutButton: {
                text: 'signout',
                click: () => {
                    const token = gapi.client.getToken();
                    if (token !== null) {
                        google.accounts.oauth2.revoke(token.access_token);
                        gapi.client.setToken('');
                        document.getElementById('calendar').innerText = '';
                        document.cookie = 'whiteboardToken=; max-age=0;';
                    }
                    location.reload();
                }
            }
        },
        locales: jaLocale,
        selectable: true,
        editable: true,
        dayMaxEvents: true,
        headerToolbar: { end: 'dayGridMonth,timeGridWeek,timeGridDay listWeek prev,today,next signoutButton', },
        initialView: width > breakPoint ? 'dayGridMonth' : 'timeGridDay',
        locale: 'ja',
        googleCalendarApiKey: 'AIzaSyAL3JeBqnhh0pTmaGMAUbh2GmkXG0tUzA0',
        datesSet: async function (dateInfo) {
            const startDate = dateInfo.start;

            // 表示年月をセット
            viewDate.setFullYear(startDate.getFullYear());
            viewDate.setMonth(startDate.getDate() === 1 ? startDate.getMonth() : startDate.getMonth() + 1);
            viewDate.setDate(1);

            // 日本の祝日をセット
            if (initDates) {
                calendar.addEventSource({
                    googleCalendarId: 'ja.japanese#holiday@group.v.calendar.google.com',
                    className: 'holiday',
                    display: 'background',
                });

                // 再取得月を設定
                let setMonth = new Date();
                reFetchMonth.push(viewDate.getMonth());

                setMonth.setMonth(viewDate.getMonth() + 4)
                reFetchMonth.push(setMonth.getMonth());

                setMonth.setMonth(viewDate.getMonth() + 8)
                reFetchMonth.push(setMonth.getMonth());

                initDates = false;
            }

            // イベントの取得
            if (reFetchMonth.includes(viewDate.getMonth())) {
                await Promise.all(accountsData.map(async account => {
                    // eventSourceを削除
                    for (let index = 1; index < calendar.getEventSources().length; index++) {
                        calendar.getEventSources()[index].remove();
                    }

                    const calendarIds = [account.privateCalendarId, account.electrodreamCalendarId];

                    // 複数のカレンダーidのイベントを格納
                    const eventData = {
                        events: await getCalenderEvents(calendarIds),
                        backgroundColor: account.backgroundColor,
                        name: account.name,
                        electrodreamCalendarId: account.electrodreamCalendarId
                    }
                    calendar.addEventSource(eventData);
                }))
            }
            defaultSources = calendar.getEventSources();
        },
        longPressDelay: 0,
        windowResize: (arg) => {
            arg.view.calendar.changeView(
                width > breakPoint ? 'dayGridMonth' : 'timeGridDay',
            );
        },
        eventClick: handleEventClick,
        select: handleSelect,
        eventDrop: handleDrop,
        eventResize: handleResize,
    });
    calendar.render();

    // 表示するメンバーリストを生成
    setMembersList();

    // モーダルに参加メンバーリストを設定
    setMembers(accountsData);
}

// モーダル保存ボタン押下処理
saveButton.addEventListener('click', async (e) => {
    const eventParams = setModalParams();
    let request;
    e.target.disabled = true;

    if (selectEventId) {
        request = gapi.client.calendar.events.update({
            calendarId: loginAccountEmail,
            eventId: selectEventId,
            resource: eventParams.google
        });
        await request.execute(function (event) {
            console.log(`${event.error ? 'error' : 'update: success'} `);

            const targetEvent = calendar.getEventById(selectEventId);
            const setData = toCalendarEvents(event);

            Object.keys(setData).map((key) => {
                if (key === 'title') {
                    targetEvent.setProp(key, setData[key]);
                } else if (key === 'start') {
                    targetEvent.setStart(setData[key]);
                } else if (key === 'end') {
                    targetEvent.setEnd(setData[key]);
                } else {
                    targetEvent.setExtendedProp(key, setData[key]);
                }
            })
        });
    } else {
        request = gapi.client.calendar.events.insert({
            calendarId: loginAccountEmail,
            resource: eventParams.google
        });
        await request.execute(function (event) {
            console.log(`${event.error ? 'error' : 'insert: success'} `);
            calendar.addEventSource([toCalendarEvents(event)]);
        });
    }
    modalClose(e.target);
});

// モーダル削除ボタン押下処理
deleteButton.addEventListener('click', async (e) => {
    e.target.closest('button').disabled = true;

    const request = gapi.client.calendar.events.delete({
        calendarId: loginAccountEmail,
        eventId: selectEventId
    });
    await request.execute(function (event) {
        console.log(`${event.error ? 'error' : 'delete: success'} `);
        calendar.getEventById(selectEventId).remove();
        modalClose(e.target.closest('button'));
    });
});

// Authorizeボタン押下処理
authorizeButton.addEventListener('click', () => {
    // TODO: 仮でプライベートアカウントを設定
    if (document.getElementById('privateEmail').value) {
        accountsData.filter(account => {
            if (account.electrodreamCalendarId === loginAccountEmail) {
                account.privateCalendarId = document.getElementById('privateEmail').value;
            };
        })
    }

    authorize(true);
});

// googleログイン
window.onGoogleLibraryLoad = () => {
    gapiLoaded();
    gisLoaded();

    google.accounts.id.initialize({
        client_id: CLIENT_ID,
        cancel_on_tap_outside: false,
        auto_select: true,
        context: 'signin',
        callback: handleCredentialResponse
    });
    google.accounts.id.prompt((notification) => {
        if (notification.isNotDisplayed() || notification.isSkippedMoment()) {
            // continue with another identity provider.
            google.accounts.id.renderButton(document.getElementById("signinDiv"), {
                theme: 'outline',
                size: 'large'
            });
        }
        document.getElementById("signinDiv").style.display = 'block';
    });
};

function handleCredentialResponse(res) {
    loginAccountEmail = jwt_decode(res.credential).email;

    // エレドリDBからアカウント情報を取得
    getAccountsData();

    maybeEnableButtons();
}